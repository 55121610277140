import React from 'react';
import '../styles/About.css';

const Kos = () => (
  <div id="kos" className="kos-container">
    <div className="kos-head">
      <h2>Kos kepada masjid, untuk kemudahan jemaah</h2>
      <p>Tiada kos kepada jemaah, Semua bebas untuk muat turun aplikasi telefon pintar Etikaf di dalam App Store dan Google Play</p>
    </div>
    <div className="kos-etikaf">
      <div className="sistem-etikaf">
        <h1>Sistem Etikaf</h1>
        <span className='kos-harga' >RM50.00</span><br /><span>/ bulan</span>
        <div className="sub-kos"><p>Kos yang terpaksa dibayar untuk server bagi membolehkan sistem ini berfungsi. Kos ini juga dapat membantu kami melakukan kerja-kerja penyelenggaraan dan naik taraf sistem kepada yang lebih baik.</p>
        </div>
      </div>
      <div className="tv-etikaf">
        <h1>ETV</h1>
        <span className='kos-harga'>RM3,000.00</span><br /><span>/ unit</span>
        <div className="sub-kos"><p>Peranti ETV adalah modul khas yang disambungkan ke skrin TV masjid. Pembelian ini adalah secara sekali bayar sahaja. 1 unit modul boleh disambungkan ke beberapa buah TV di dalam masjid.</p>
        </div>
      </div>
    </div>
  </div>
);

export default Kos;
