// fetchPrayerTimesFromAPI.js
const BASE_URL = 'https://www.e-solat.gov.my/index.php?r=esolatApi/TakwimSolat';

const getTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return `${tomorrow.getFullYear()}-${String(tomorrow.getMonth() + 1).padStart(2, '0')}-${String(tomorrow.getDate()).padStart(2, '0')}`;
};

const fetchPrayerTimesFromAPI = async (zoneCode) => {
    const todayUrl = `${BASE_URL}&period=today&zone=${zoneCode}`;
    const tomorrowUrl = `${BASE_URL}&period=date&zone=${zoneCode}&date=${getTomorrowDate()}`;

    try {
        // Fetch today's prayer times
        const todayResponse = await fetch(todayUrl);
        const todayData = await todayResponse.json();
        console.log('Today\'s prayer times data:', todayData);

        // Calculate Dhuha time
        if (todayData.status === "OK!" && todayData.prayerTime.length > 0) {
            const syurukTime = todayData.prayerTime[0].syuruk.split(':');
            const dhuhaTime = new Date(0, 0, 0, syurukTime[0], parseInt(syurukTime[1]) + 28, syurukTime[2]);
            todayData.prayerTime[0].dhuha = dhuhaTime.toTimeString().split(' ')[0];
            console.log('Today\'s data w dhuha:', todayData);
        }

        // Fetch tomorrow's Hijri date
        const tomorrowResponse = await fetch(tomorrowUrl);
        const tomorrowData = await tomorrowResponse.json();
        console.log('Tomorrow\'s prayer times data:', tomorrowData);

        // Store fetched data in local storage
        const storedData = {
            todayTimes: todayData.prayerTime[0],
            tomorrowHijri: tomorrowData.prayerTime[0].hijri
        };
        localStorage.setItem(`prayerTimes_${zoneCode}`, JSON.stringify(storedData));

        return storedData;
    } catch (error) {
        console.error('Failed to fetch prayer times: ', error);

        // Attempt to use cached data if fetching fails
        const cachedData = localStorage.getItem(`prayerTimes_${zoneCode}`);
        if (cachedData) {
            return JSON.parse(cachedData);
        } else {
            throw error;
        }
    }
};

export default fetchPrayerTimesFromAPI;
