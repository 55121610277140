export const getHariLong = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: 'long' };
    return date.toLocaleDateString('ms-MY', options);
};

export const getTarikh = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ms-MY', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    });
};

export const getSubjek = (subjectId) => {
    const subjects = {
        1: 'Tauhid',
        2: 'Fekah',
        3: 'Hadis',
        4: 'Tafsir',
        5: 'Umum',
    };
    return subjects[subjectId] || 'Umum';
};

export const formatCustomDate = (dateString) => {
    const date = new Date(dateString);
    const dayNames = ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', 'Jumaat', 'Sabtu'];
    const monthNames = ['Jan', 'Feb', 'Mac', 'Apr', 'Mei', 'Jun', 'Jul', 'Ogo', 'Sep', 'Okt', 'Nov', 'Dis'];
    const day = dayNames[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;

    return `${day}, ${dayOfMonth} ${month} ${year}, ${hours}:${minutesStr} ${ampm}`;
};

export const capitalizeWords = (text) => {
    if (!text) return "";
    return text
        .toLowerCase() // Ensure all letters are lowercase first
        .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize each word
};
