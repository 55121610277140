import React, { useState, useEffect } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { dbAdmin } from "../firebaseConfig"; // Firestore for V2
import Body from "../components/Body";
import Solat from "../components/Solat";
import Masjid from "../components/Masjid";
import "../styles/Main.css";

function Main() {
  const [v2Masjids, setV2Masjids] = useState([]);

  // Fetch V2 (Firestore) Masjid Data
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(dbAdmin, "masajid"), (snapshot) => {
      const v2Data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log("🔥 V2 Masjid Data:", v2Data);
      setV2Masjids(v2Data);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="main-container">
      <div style={{ marginTop: "76px" }}>
        <Solat />
      </div>
      <div>
        <Masjid v2Masjids={v2Masjids} />
      </div>
      <div>
        <Body v2Masjids={v2Masjids} />
        <div style={{ height: "60px" }}></div>
      </div>
    </div>
  );
}

export default Main;
