import React from 'react';
import '../styles/About.css';
import Pengenalan from '../components/Pengenalan';
import Mula from '../components/Mula';
import Ciri from '../components/Ciri';
import Etv from '../components/Etv';
import Kos from '../components/Kos';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faArrowUpLong } from '@fortawesome/free-solid-svg-icons';

function About() {

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error(`Element with ID ${id} not found.`);
    }
  };

  const scrollToTop = () => {
    const topElement = document.getElementById('top');
    if (topElement) {
      topElement.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error('Top element not found.');
    }
  };

  return (
    <div id="top" className="about-top">

      <nav className="top-menu">
        <ul className="top-buttons">
          <li><span className='menu-title' onClick={() => scrollToSection('pengenalan')}>Pengenalan</span></li>
          <li><span className='menu-title' onClick={() => scrollToSection('mula')}>Mula</span></li>
          <li><span className='menu-title' onClick={() => scrollToSection('ciri')}>Ciri</span></li>
          <li><span className='menu-title' onClick={() => scrollToSection('etv')}>Etv</span></li>
          <li><span className='menu-title' onClick={() => scrollToSection('kos')}>Kos</span></li>
        </ul>

        <FontAwesomeIcon className="icon-close" icon={faClose} onClick={() => window.location.href = '/'} />
      </nav>

      <Pengenalan id="pengenalan" />
      <Mula id="mula" />
      <Ciri id="ciri" />
      <Etv id="etv" />
      <Kos id="kos" />
      <div style={{ height: "100px" }}></div>

      {/* Fixed button at bottom right */}
      <div className="fixed-button">
        <button onClick={scrollToTop}>
          <FontAwesomeIcon className="icon-up" icon={faArrowUpLong} />
        </button>
      </div>
    </div>

  );
}

export default About;
