import React, { useState, useEffect, useRef, useCallback } from 'react';
import { convertToArabicHijriDate, prayerNames, formatTime, getDayDate } from '../utils/prayerUtils';
import usePrayerTimes from '../utils/usePrayerTimes';
import '../styles/Solat.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faTimes, faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { zones } from '../utils/zonJakim';

function Solat() {
    const [showSearch, setShowSearch] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState({ code: "WLY01", name: "Kuala Lumpur, Putrajaya" });
    const [searchValue, setSearchValue] = useState('');
    const [inputValue, setInputValue] = useState(selectedLocation.name);
    const [isInputClicked, setIsInputClicked] = useState(false);
    const containerRef = useRef(null);

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };

    const handleClickOutside = useCallback((event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            if (!searchValue) {
                setInputValue(selectedLocation.name);
            }
            setShowSearch(false);
            setSearchValue('');
            setIsInputClicked(false);
        }
    }, [searchValue, selectedLocation.name]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const filteredZones = zones.map(zone => ({
        ...zone,
        zones: zone.zones.filter(location => location.name.toLowerCase().includes(searchValue.toLowerCase()))
    })).filter(zone => zone.zones.length > 0);

    const handleLocationSelect = (location) => {
        handleLocationChange({ target: { value: location.code } });
        setInputValue(location.name);
        setSearchValue('');
        setShowSearch(false);
        setIsInputClicked(false);
    };

    const handleInputClick = () => {
        setInputValue('');
        setSearchValue('');
        setIsInputClicked(true);
    };

    useEffect(() => {
        const savedLocation = JSON.parse(localStorage.getItem('selectedLocation'));
        if (savedLocation) {
            setSelectedLocation(savedLocation);
            setInputValue(savedLocation.name);
        }
    }, []);

    const { prayerTimes, currentPrayer, nextPrayer, countdown, dayDate, hijriDate, countdownZero } = usePrayerTimes(selectedLocation.code);

    useEffect(() => {
        if (selectedLocation.code) {
            console.log(`Fetching prayer times for new location: ${selectedLocation.name} (${selectedLocation.code})`);
        }
    }, [selectedLocation]);

    useEffect(() => {
        console.log(`Current Prayer: ${currentPrayer} -> ${prayerNames[currentPrayer]?.display}`);
        console.log(`Next Prayer: ${nextPrayer} -> ${prayerNames[nextPrayer]?.display}`);
    }, [currentPrayer, nextPrayer]);

    const toggleSearchContainer = () => {
        setShowSearch(!showSearch);
        setIsInputClicked(false);
    };

    const handleLocationChange = (event) => {
        const selectedCode = event.target.value;
        const selectedZone = zones.flatMap(zone => zone.zones).find(zone => zone.code === selectedCode);
        if (selectedZone) {
            const newLocation = { code: selectedZone.code, name: selectedZone.name };
            setSelectedLocation(newLocation);
            setInputValue(newLocation.name);
            localStorage.setItem('selectedLocation', JSON.stringify(newLocation));
        }
        setShowSearch(false);
    };

    return (
        <div ref={containerRef}>
            {countdownZero ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <div style={{ fontSize: '24px' }}>
                        Telah masuk waktu {prayerNames[currentPrayer]?.display}  <span style={{ fontFamily: "Noto Naskh Arabic" }}>{prayerNames[currentPrayer]?.arabic}</span>
                    </div>
                    <div style={{ fontSize: '14px' }}>
                        Bagi kawasan {selectedLocation?.name}
                    </div>
                </div>

            ) : (
                <>

                    <div className="prayer-times-container">
                        {['imsak', 'fajr', 'syuruk', 'dhuha', 'dhuhr', 'asr', 'maghrib', 'isha'].map((name, idx) => (
                            <div key={idx} className="prayer-times-item" style={{ flex: 1, display: 'flex', flexDirection: 'column', paddingLeft: "15px", borderRight: idx < 7 ? '1px solid #ccc' : 'none' }}>
                                <div className={`prayer-times-name ${currentPrayer === name ? 'current-prayer' : ''}`}>
                                    {prayerNames[name]?.display}
                                </div>
                                <div>{prayerTimes && prayerTimes[name] ? formatTime(prayerTimes[name]) : '---'}</div>
                            </div>
                        ))}
                    </div>

                    <div className="info-container" style={{ display: 'flex', justifyContent: 'space-between', fontSize: "14px", paddingTop: "15px", color: "grey" }}>
                        <div>{getDayDate(dayDate)} <span style={{ fontFamily: "Noto Naskh Arabic" }}>{hijriDate && convertToArabicHijriDate(hijriDate)}</span></div>
                        <div className='icon-container' onClick={toggleSearchContainer}>
                            <FontAwesomeIcon className="icon-import" icon={faLocationDot} />
                            <span className="location-name">{selectedLocation.name}</span>
                        </div>

                        <div className='next-prayer-container'>
                            <FontAwesomeIcon className="icon-right" icon={faAnglesRight} />
                            {nextPrayer && prayerNames[nextPrayer] ? prayerNames[nextPrayer]?.display : '---'}
                            <div className="countdown-container">
                                {countdown ? countdown.split(':').map((time, index) => (
                                    <div key={index} className="countdown-box">{time}</div>
                                )) : <div className="countdown-box">--:--:--</div>}
                            </div>
                        </div>
                    </div>

                    {showSearch && (
                        <div className='search-container'>
                            <div className='search-input-close'>
                                <input
                                    type="text"
                                    placeholder={selectedLocation.name}
                                    value={searchValue || inputValue}
                                    onChange={handleSearchChange}
                                    onClick={handleInputClick}
                                    className='search-input'
                                />
                                <button onClick={toggleSearchContainer} className='close-button'>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                            <div className={`zones-list-container ${isInputClicked ? 'show' : ''}`}>
                                {filteredZones.map((zone, idx) => (
                                    <div key={idx} className='zone-group'>
                                        <div className='state-title'>{zone.title}</div>
                                        <ul className='zone-list'>
                                            {zone.zones.map((location) => (
                                                <li key={location.code} onClick={() => handleLocationSelect(location)}>
                                                    {location.name}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default Solat;
