import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Main from './pages/Main';
import About from './pages/About';
import Privasi from './pages/Privasi';
import Kemaskini from './pages/Kemaskini';
import Hubungi from './pages/Hubungi';
import Mengenai from './pages/Mengenai';
import EtvMain from './pages/EtvMain';


// import Login from './pages/Login';
// import Daftar from './pages/Daftar';

import NotFound from './pages/NotFound';
import Footer from './components/Footer';
import Header from './components/Header';
import './App.css';

function Layout() {
  const location = useLocation();
  // Use toLowerCase() to ensure the path check is case-insensitive
  const isLoginPage = location.pathname.toLowerCase() === '/login';
  const isDaftarPage = location.pathname.toLowerCase() === '/daftar';


  return (
    <div className="App">
      {(!isLoginPage && !isDaftarPage) && <Header />}
      <main className="container">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/About" element={<About />} />
          {/* <Route path="/Login" element={<Login />} />
          <Route path="/Daftar" element={<Daftar />} /> */}
          <Route path="/privasi" element={<Privasi />} />
          <Route path="/kemaskini" element={<Kemaskini />} />
          <Route path="/hubungi" element={<Hubungi />} />
          <Route path="/mengenai" element={<Mengenai />} />
          <Route path="/etv" element={<EtvMain />} />


          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}


function App() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;
