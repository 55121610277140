import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../styles/About.css';

const Mula = () => {
  const navigate = useNavigate();  // Get the navigate function

  return (
    <div id="mula" className='mula-container'>
      <div className='mula-kiri'>
        <img className="img-mula" src="assets/feature3.png" alt="Feature" />
      </div>

      <div className='mula-kanan'>
        <h4>BAGAIMANA UNTUK MULA</h4>
        <h2>Pihak masjid mendaftar untuk menggunakan Sistem Etikaf</h2>
        <p>Akaun masjid yang tersendiri akan diberikan untuk menguruskan aktiviti di dalam sistem</p>
        <button  onClick={() => navigate('/daftar')}>Daftar</button>
      </div>
    </div>
  );
};

export default Mula;
