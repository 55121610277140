import React from "react";
import "../styles/Hubungi.css";
import { FaWhatsapp, FaEnvelope, FaUserCircle } from "react-icons/fa";


const contacts = [
    { name: "Admin Etikaf", email: "admin@etikaf.com", phone: "+6011-5888 5060" },
];

const Hubungi = () => {
    return (
        <div className="hubungi-container">
            <h2>Hubungi Kami</h2>
            <div className="hubungi-list">
                {contacts.map((contact, index) => (
                    <div key={index} className="hubungi-item">
                        <FaUserCircle className="hubungi-icon" />

                        <h3 className="hubungi-name">{contact.name}</h3>
                        <FaEnvelope className="hubungi-icon" />

                        <p className="hubungi-email">{contact.email}</p>
                        <FaWhatsapp className="hubungi-icon" />

                        <p className="hubungi-phone">{contact.phone}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Hubungi;
