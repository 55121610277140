import React, { useEffect, useState, useRef } from "react";

import "../styles/Masjid.css";

function Masjid({ v2Masjids }) {
  const [v1Mosques, setV1Mosques] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [itemsToShow, setItemsToShow] = useState(6);
  const containerRef = useRef(null);

  // Fetch V1 Data (API)
  useEffect(() => {
    fetch("https://v1.etikaf.com/api/v1/mosques")
      .then((response) => response.json())
      .then((data) => {
        if (data.success && Array.isArray(data.data)) {
          setV1Mosques(data.data);
        } else {
          console.error("Unexpected data format:", data);
        }
      })
      .catch((error) => console.error("Error fetching V1 mosques:", error));
  }, []);




  // Merge V2 and V1, with V2 first
  const mergedMosques = [...v2Masjids, ...v1Mosques];

  const handleMouseDown = (e) => {
    e.preventDefault();
    const startX = e.pageX;
    const scrollLeft = containerRef.current.scrollLeft;

    const handleMouseMove = (e) => {
      const walk = (e.pageX - startX) * 1; // 1 is the scroll speed
      containerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth > 1800) {
      setItemsToShow(7);
    } else if (screenWidth > 1500) {
      setItemsToShow(6);
    } else if (screenWidth > 1300) {
      setItemsToShow(5);
    } else if (screenWidth > 1100) {
      setItemsToShow(4);
    } else if (screenWidth > 500) {
      setItemsToShow(3);
    } else if (screenWidth > 370) {
      setItemsToShow(2);
    } else {
      setItemsToShow(1);
    }
  }, [screenWidth]);

  return (
    <div>
      <hr className="hairline" />
      <div
        className="mosque-container"
        ref={containerRef}
        onMouseDown={handleMouseDown}
      >
        {mergedMosques.slice(0, showMore ? mergedMosques.length : itemsToShow).map((mosque, index) => (
          <div key={index} className="mosque-card">
            <img src={mosque.photo_src || mosque.profail?.logo} alt={mosque.name || mosque.profail?.nama} className="mosque-image" />
            <div className="mosque-name">{mosque.name || mosque.profail?.nama}</div>
          </div>
        ))}


        {mergedMosques.length > itemsToShow && (
          <button className="more-button" onClick={() => setShowMore(!showMore)}>
            {showMore ? "Sikit" : "Lagi"}
          </button>
        )}
      </div>
      <hr className="hairline" />
    </div>
  );
}

export default Masjid;
