import React from 'react';
import '../styles/About.css';

const Etv = () => (
      <div id='etv' className="etv-container">
        <div className="etv-text">
          <h4>TV Masjid</h4>
          <h2>ETV adalah medium untuk menyalurkan informasi melalui TV di masjid</h2>
          <p>ETV (Etikaf TV) selain fungsinya menjadi jam digital iaitu memaparkan waktu semasa, kandungan lainnya adalah :</p>
          <ul>
            <li><span>Maklumat Kuliah Terkini</span></li>
            <li><span>Pengumuman</span></li>
            <li><span>Poster-Poster Ilmiah</span></li>
            <li><span>Waktu-Waktu Solat</span></li>
            <li><span>Kira Detik waktu solat</span></li>
          </ul>
        </div>
        <div className="etv-image">
          <img className="img-etv" src="assets/ETV.gif" height="300" alt="Feature" />
        </div>
      </div>
);

export default Etv;
