// NotFoundPage.js
import React from 'react';

const NotFound = () => {
  return (
    <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
      <h1>404</h1>
      <p>Maaf, tak wujud</p>
    </div>
  );
};

export default NotFound;
