import React from "react";
import "../styles/Kemaskini.css";

const updates = [
    { date: "14 Januari 2025", type: "Etikaf Web", title: "Website Etikaf Baru", description: "Susunatur dan rekabentuk yang baru" },
];

const Kemaskini = () => {
    return (
        <div className="kemaskini-container">
            <h2>Kemaskini Sistem</h2>
            <div className="kemaskini-list">
                {updates.map((update, index) => (
                    <div key={index} className="kemaskini-item">
                        <span className="kemaskini-date">{update.date}</span>
                        <span className="kemaskini-type">{update.type}</span>
                        <h3 className="kemaskini-title">{update.title}</h3>
                        <p className="kemaskini-description">{update.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Kemaskini;
