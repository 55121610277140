import React from 'react';
import '../styles/About.css';

const Ciri = () => (

  <div id='ciri' className="ciri-container">
    <div className="ciri-kiri">
      <h2>Sistem yang memahami keperluan masjid</h2>
      <p>Sistem Etikaf dapat memudahkan pihak masjid dalam menguruskan aktiviti dengan cepat dan pantas. Pelbagai kemudahan ada di dalam sistem ini yang pastinya dapat membuatkan perancangan masjid lebih teratur. Sistem ini dibangunkan khusus untuk keperluan pihak masjid dengan adanya kalendar khas yang dapat mencipta aktiviti atau program masjid dengan mudah.</p>
    </div>

    <div className="ciri-kanan">
      <div style={{ display: "flex" }}>
        <div className='ciri-icon'>
          <img src="assets/022-laptop.svg" width="60" alt="Icon" />

          <h4>Web Aplikasi</h4>
          <p className="d-none d-md-block">Pihak masjid dapat akses sistem dimana-mana sahaja</p>
        </div>
        <div className='ciri-icon'>
          <img src="assets/014-printer.svg" width="60" alt="Icon" />

          <h4>Jana PDF</h4>
          <p className="d-none d-md-block">Takwim kuliah bulanan secara automatik dari sistem</p>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div className='ciri-icon'>
          <img src="assets/021-mobile.svg" width="60" alt="Icon" />

          <h4>Aplikasi Telefon Pintar</h4>
          <p className="d-none d-md-block">Jemaah boleh muat turun aplikasi secara percuma</p>
        </div>
        <div className='ciri-icon'>
          <img src="assets/020-monitor.svg" width="60" alt="Icon" />

          <h4>TV Masjid</h4>
          <p className="d-none d-md-block">Maklumat di dalam telefon pintar, juga ada di TV Masjid</p>
        </div>
      </div>


    </div>


  </div>

);

export default Ciri;
