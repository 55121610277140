import React from 'react';

export const prayerNames = {
    imsak: { display: 'Imsak', arabic: 'إمساك' },
    fajr: { display: 'Subuh', arabic: 'صبح' },
    dhuha: { display: 'Dhuha', arabic: 'ضحى' },
    syuruk: { display: 'Syuruk', arabic: 'شروق' },
    dhuhr: { display: 'Zohor', arabic: 'ظهر' },
    asr: { display: 'Asar', arabic: 'عصر' },
    maghrib: { display: 'Maghrib', arabic: 'مغرب' },
    isha: { display: 'Isyak', arabic: 'عشاء' }
};

export const formatTime = (time) => {
    if (!time) return null;
    const [hours, minutes, seconds] = time.split(':');
    const date = new Date();
    date.setHours(hours, minutes, seconds);
    const timeString = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

    // Split the time into main time and am/pm parts
    const [mainTime, period] = timeString.split(' ');

    return (
        <span>
            {mainTime}
            <span style={{ color:"grey", fontSize: '8px', border: '0.1px solid grey', backgroundColor: 'transparent', padding: '0 4px', borderRadius: '4px', marginLeft: '4px' }}>
                {period}
            </span>
        </span>
    );
};

export const convertToArabicHijriDate = (hijriDate) => {
    const [year, month, day] = hijriDate.split('-');
    const arabicMonths = [
        'محرم', 'صفر', 'ربيع الأول', 'ربيع الثاني', 'جمادى الأولى', 'جمادى الآخرة',
        'رجب', 'شعبان', 'رمضان', 'شوال', 'ذوالقعدة', 'ذوالحجة'
    ];
    const arabicDigits = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
    const arabicYear = year.split('').map(digit => arabicDigits[parseInt(digit)]).join('');
    const arabicMonth = arabicMonths[parseInt(month) - 1];
    const arabicDay = parseInt(day) < 10 ? arabicDigits[parseInt(day)] : day.split('').map(digit => arabicDigits[parseInt(digit)]).join('');
    return `${arabicDay} ${arabicMonth} ${arabicYear}`;
};

export const getDayDate = () => {
    const now = new Date();
    const dayNames = ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', 'Jumaat', 'Sabtu'];
    const monthNames = ['Januari', 'Februari', 'Mac', 'April', 'Mei', 'Jun', 'Julai', 'Ogos', 'September', 'Oktober', 'November', 'Disember'];
    const day = dayNames[now.getDay()];
    const date = now.getDate();
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
    return `${day}, ${date} ${month} ${year} |`;
};


export const parseTime = (dateString, timeString) => {
    if (!timeString) return null;
    const [hours, minutes] = timeString.split(':');
    const [day, month, year] = dateString.split('-');

    const monthNames = {
        'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04',
        'May': '05', 'Jun': '06', 'Jul': '07', 'Aug': '08',
        'Sep': '09', 'Oct': '10', 'Nov': '11', 'Dec': '12'
    };

    const monthNumber = monthNames[month];

    if (!monthNumber) return null;  // If the month is not recognized

    const date = new Date(`${year}-${monthNumber}-${day}T${hours}:${minutes}:00`);
    return date;
};

