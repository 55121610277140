import { useState, useEffect, useRef, useCallback } from 'react';
import fetchPrayerTimesFromAPI from './fetchPrayerTimesFromAPI';

const usePrayerTimes = (zoneCode) => {
    const [prayerTimes, setPrayerTimes] = useState(null);
    const [currentPrayer, setCurrentPrayer] = useState('');
    const [nextPrayer, setNextPrayer] = useState('');
    const [countdown, setCountdown] = useState('--:--:--');
    const [countdownZero, setCountdownZero] = useState(false);
    const [dayDate, setDayDate] = useState('');
    const [hijriDate, setHijriDate] = useState('');
    const [tomorrowHijriDate, setTomorrowHijriDate] = useState('');

    const [nextDayPrayerTimes, setNextDayPrayerTimes] = useState(null);
    const [isNextPrayerCalculated, setIsNextPrayerCalculated] = useState(false);
    const countdownTimerRef = useRef(null);

    const parseTimeToSeconds = useCallback((time) => {
        if (!time) return 0;
        const [hours, minutes, seconds] = time.split(':').map(Number);
        const totalSeconds = hours * 3600 + minutes * 60 + (seconds || 0);
        console.log(`Parsed time ${time} to ${totalSeconds} seconds`);
        return totalSeconds;
    }, []);

    const formatCountdown = useCallback((remainingSeconds) => {
        const hours = String(Math.floor(remainingSeconds / 3600)).padStart(2, '0');
        const minutes = String(Math.floor((remainingSeconds % 3600) / 60)).padStart(2, '0');
        const seconds = String(remainingSeconds % 60).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    }, []);

    const calculateNextPrayer = useCallback((times, nextDayTimes) => {
        const setupCountdown = (nextPrayerTimeInSeconds) => {
            if (countdownTimerRef.current) {
                clearInterval(countdownTimerRef.current);
            }

            console.log(`Setting up countdown for next prayer in ${nextPrayerTimeInSeconds} seconds`);

            countdownTimerRef.current = setInterval(() => {
                const now = new Date();
                const nowInSeconds = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
                const remainingSeconds = nextPrayerTimeInSeconds - nowInSeconds;

                if (remainingSeconds <= 0) {
                    clearInterval(countdownTimerRef.current);
                    setCountdownZero(true);
                    setTimeout(() => setCountdownZero(false), 60000); // Reset after 1 minute
                    calculateNextPrayer(prayerTimes, nextDayPrayerTimes); // Recalculate next prayer times
                    console.log('Countdown finished, recalculating next prayer times');
                } else {
                    setCountdown(formatCountdown(remainingSeconds));
                    // console.log(`Countdown: ${formatCountdown(remainingSeconds)}`);
                }
            }, 1000);
        };

        if (!times) {
            console.error('No prayer times available.');
            return;
        }

        const now = new Date();
        const nowInSeconds = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
        console.log(`Current time in seconds: ${nowInSeconds}`);
        const prayers = ['imsak', 'fajr', 'syuruk', 'dhuha', 'dhuhr', 'asr', 'maghrib', 'isha'];

        const timesInSeconds = prayers.map(prayer => {
            const timeInSeconds = parseTimeToSeconds(times[prayer]);
            console.log(`Time for ${prayer}: ${timeInSeconds} seconds (${times[prayer]})`);
            return {
                name: prayer,
                time: timeInSeconds
            };
        });

        // Add next day's imsak time to the end of the array for midnight handling
        if (nextDayTimes) {
            timesInSeconds.push({
                name: 'imsak',
                time: parseTimeToSeconds(nextDayTimes.imsak) + 86400 // Add 24 hours in seconds
            });
            prayers.push('imsak');
        }

        console.log('Times in seconds:', timesInSeconds);

        const currentIndex = timesInSeconds.findIndex(pt => pt.time > nowInSeconds);
        const nextIndex = currentIndex === -1 ? 0 : currentIndex;
        const previousIndex = (currentIndex === -1 ? timesInSeconds.length : currentIndex) - 1;

        console.log(`Current index: ${currentIndex}`);
        console.log(`Previous index: ${previousIndex}`);
        console.log(`Next index: ${nextIndex}`);

        setCurrentPrayer(prayers[previousIndex]);
        setNextPrayer(prayers[nextIndex]);

        // Check if we have passed Maghrib time and update Hijri date if needed
        if (timesInSeconds[prayers.indexOf('maghrib')].time < nowInSeconds) {
            setHijriDate(tomorrowHijriDate);
        } else {
            setHijriDate(times.hijri);
        }



        console.log(`Current Prayer: ${prayers[previousIndex]}`);
        console.log(`Next Prayer: ${prayers[nextIndex]}`);

        setupCountdown(timesInSeconds[nextIndex].time);
    }, [parseTimeToSeconds, formatCountdown, prayerTimes, nextDayPrayerTimes, tomorrowHijriDate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log(`Fetching prayer times for zone: ${zoneCode}`);
                const data = await fetchPrayerTimesFromAPI(zoneCode);
                console.log('Fetched data:', data);

                setPrayerTimes(data.todayTimes);
                setDayDate(data.todayTimes.date);
                setHijriDate(data.todayTimes.hijri);
                setTomorrowHijriDate(data.tomorrowHijri);


                const nextDayData = await fetchPrayerTimesFromAPI(zoneCode, 'tomorrow');
                console.log('Fetched next day data:', nextDayData);
                setNextDayPrayerTimes(nextDayData.todayTimes);

                setIsNextPrayerCalculated(false); // Allow next prayer calculation
            } catch (error) {
                console.error('Error fetching prayer times:', error);
            }
        };

        fetchData();

        return () => {
            if (countdownTimerRef.current) {
                clearInterval(countdownTimerRef.current);
                countdownTimerRef.current = null;
            }
        };
    }, [zoneCode]);

    useEffect(() => {
        if (prayerTimes && !isNextPrayerCalculated) {
            calculateNextPrayer(prayerTimes, nextDayPrayerTimes);
            setIsNextPrayerCalculated(true); // Ensure calculation only happens once per data fetch
        }
    }, [prayerTimes, nextDayPrayerTimes, isNextPrayerCalculated, calculateNextPrayer]);

    return { prayerTimes, currentPrayer, nextPrayer, countdown, countdownZero, dayDate, hijriDate };
};

export default usePrayerTimes;
