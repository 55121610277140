import React from 'react';
import '../styles/About.css';

const Pengenalan = () => (
  <div id="pengenalan" className="pengenalan-container">
    <div className="pengenalan-atas">
      <div className="pengenalan-kiri">
        <h2>Etikaf adalah sistem yang dibangunkan untuk kemudahan masjid dan orang ramai berhubung.</h2>
        <p>Orang ramai mudah mendapatkan maklumat yang disediakan oleh pihak masjid melalui sistem ini.</p>
      </div>
      <div className="pengenalan-kanan">
        <img className="img-konsep" src="assets/etikaf-konsep.png" alt="Konsep" />

      </div>
    </div>
    <div className="pengenalan-bawah">
      <h2>Menghubungkan komuniti masjid</h2>
      <span> Masjid dan orang ramai (jemaah) dapat berhubung di dalam platform ini</span>
    </div>
    <div className="pengenalan-masjid">
      <div className="pengenalan-masjid-bawah">
        <img className="img-masjid" src="assets/feature2.png" alt="Feature" />
        <h2>Masjid</h2>
        <span>Pengurusan masjid menguruskan aktiviti dan program melalui sistem ini.</span>
      </div>

      <div className="pengenalan-masjid-bawah">
        <img className="img-jemaah" src="assets/feature1.png" alt="Feature" />
        <h2>Jemaah</h2>
        <span>Orang ramai dapat makluman berkenan aktiviti dan program masjid melalui telefon pintar.</span>
      </div>
    </div>
  </div>


);

export default Pengenalan;
