import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useHeaderStyle } from '../utils/useHeaderStyle'; // Assuming your custom hook is stored here
import { useLocation } from 'react-router-dom'; // Import useLocation

const Header = () => {
  const headerStyle = useHeaderStyle();
  const location = useLocation(); // Get current location

  // Determine if we are on the 'About' page
  const isAboutPage = location.pathname === '/about';

  return (
    <header className="app-header" style={headerStyle}>
      <div className='content-header'>
        <img src="/assets/etikaf-arab.svg" className="App-logo" alt="logo" onClick={() => window.location.href = '/'} />
        <nav>
          {!isAboutPage && ( // Only render this div if not on the 'About' page
            <div className="icon-text-container">
              <FontAwesomeIcon className="icon-import" icon={faInfoCircle} onClick={() => window.location.href = '/about'} />
              <span className="icon-text">Mengenai Etikaf</span>
            </div>
          )}
          {/* <button onClick={() => window.location.href = '/login'}>Log Masuk</button> Corrected onClick */}
          <button onClick={() => window.location.href = 'https://etikaf-admin.web.app/login'}>Log Masuk</button>

        </nav>
      </div>
    </header>
  );
};

export default Header;
