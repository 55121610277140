import React from "react";
import "../styles/Privasi.css"; // Import the CSS file for styling

const Privasi = () => {
    return (
        <div className="privasi-container">
            <h1>Dasar Privasi</h1>
            <p><strong>Etikaf (com.etikaf.app)</strong></p>
            <p><strong>Tarikh Berkuatkuasa:</strong> 14 Januari 2025</p>

            <section>
                <h2>Pengenalan</h2>
                <p>
                    Etikaf ("kami", "kita", atau "syarikat kami") menghormati privasi anda. Dasar
                    Privasi ini menerangkan bagaimana kami mengumpul, menggunakan, dan berkongsi
                    maklumat anda apabila anda menggunakan aplikasi kami.
                </p>
            </section>

            <section>
                <h2>Pengumpulan Data</h2>
                <p>Aplikasi kami mungkin mengumpul jenis data berikut:</p>
                <ul>
                    <li>Maklumat peribadi (contoh: nama, emel) jika diberikan.</li>
                    <li>Data penggunaan untuk tujuan analitik.</li>
                    <li>Data lokasi (jika diaktifkan oleh pengguna).</li>
                </ul>
            </section>

            <section>
                <h2>Penggunaan Data</h2>
                <p>Kami menggunakan data yang dikumpul untuk:</p>
                <ul>
                    <li>Meningkatkan fungsi aplikasi.</li>
                    <li>Menyediakan pengalaman yang diperibadikan.</li>
                    <li>Menghantar notifikasi dan kemas kini berkaitan ciri aplikasi.</li>
                </ul>
            </section>

            <section>
                <h2>Perkongsian Data</h2>
                <p>
                    Kami tidak berkongsi data anda dengan pihak ketiga kecuali diperlukan untuk
                    pematuhan undang-undang.
                </p>
                <p>
                    Kami juga menggunakan perkhidmatan pihak ketiga berikut, yang mungkin memproses
                    data anda:
                </p>
                <ul>
                    <li>
                        Firebase Analytics (
                        <a
                            href="https://firebase.google.com/support/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Dasar Privasi Firebase
                        </a>
                        )
                    </li>
                </ul>
            </section>

            <section>
                <h2>Penyimpanan Data</h2>
                <p>
                    Kami menyimpan data hanya untuk tempoh yang diperlukan untuk memenuhi tujuan
                    yang dinyatakan dalam dasar ini.
                </p>
            </section>

            <section>
                <h2>Keselamatan Data</h2>
                <p>
                    Kami melaksanakan langkah keselamatan piawaian industri untuk melindungi data
                    anda.
                </p>
            </section>

            <section>
                <h2>Hubungi Kami</h2>
                <p>
                    Jika anda mempunyai sebarang soalan tentang Dasar Privasi ini, sila hubungi kami
                    di:
                </p>
                <p>
                    <strong>Emel:</strong>{" "}
                    <a href="mailto:privacy@etikaf.com">admin@etikaf.com</a>
                </p>
            </section>
        </div>
    );
};

export default Privasi;
