import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Firebase Configurations for different projects
const firebaseConfigs = {
    admin: {
        apiKey: "AIzaSyB5htBstObXmLn7HKdT9oNiPgDGwQ7zwJE",
        authDomain: "etikaf-admin.firebaseapp.com",
        projectId: "etikaf-admin",
        storageBucket: "etikaf-admin.appspot.com",
        messagingSenderId: "210475893981",
        appId: "1:210475893981:web:6b1368a6579a38872fc734",
        measurementId: "G-R1DDERWF98"
    },
    ustaz: {
        apiKey: "USTAZ_API_KEY",
        authDomain: "USTAZ_AUTH_DOMAIN",
        projectId: "etikaf-ustaz",
        storageBucket: "USTAZ_STORAGE_BUCKET",
        messagingSenderId: "USTAZ_MESSAGING_SENDER_ID",
        appId: "USTAZ_APP_ID",
    },
};

// Function to get Firebase App Instance
const getFirebaseApp = (name) => {
    if (!getApps().some(app => app.name === name)) {
        return initializeApp(firebaseConfigs[name], name);
    }
    return getApp(name);
};

// Initialize Firestore for each project
const dbAdmin = getFirestore(getFirebaseApp("admin"));
const dbUstaz = getFirestore(getFirebaseApp("ustaz"));

export { dbAdmin, dbUstaz };
