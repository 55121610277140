import React, { useState, useEffect } from "react";
import axios from "axios";
import ImageSlider from "../components/ImageSlider";
import { collection, onSnapshot } from "firebase/firestore";
import { dbAdmin } from "../firebaseConfig"; // Firestore for V2
import "../styles/Body.css";
import { getHariLong, getTarikh, getSubjek, formatCustomDate, capitalizeWords } from "../utils/formatHelpers";

const Body = ({ v2Masjids }) => {
    const [kuliahData, setKuliahData] = useState([]);
    const [etvData, setEtvData] = useState([]);
    const [v2Kuliah, setV2Kuliah] = useState([]);
    const [v2Etv, setV2Etv] = useState([]);



    // Fetch Kuliah Data V1
    useEffect(() => {
        const fetchData = async () => {
            const url = "https://v1.etikaf.com/api/v1/latestkuliah?limit=12&category_id=1";
            try {
                const response = await fetch(url);
                const result = await response.json();
                if (result.data) {
                    setKuliahData(result.data);
                }
            } catch (error) {
                console.error("Error fetching kuliah data:", error);
            }
        };

        fetchData();
    }, []);

    // Fetch ETV Data v1
    useEffect(() => {
        const fetchData = async () => {
            const url = "https://v1.etikaf.com/api/v1/latestetvposter?limit=9";
            try {
                const response = await axios.get(url);
                if (response.data && response.data.data) {
                    const filteredEtv = response.data.data.filter(etv => ![4, 2, 10].includes(etv.mosque_id));
                    console.log("🔥 Filtered V1 ETV Data (Excluded mosque_id 4,2,10):", filteredEtv);
                    setEtvData(filteredEtv);
                }
            } catch (error) {
                console.error("Error fetching ETV data:", error);
            }
        };

        fetchData();
    }, []);




    // Fetch V2 (Firestore) Kuliah Data
    useEffect(() => {
        const unsubscribe = onSnapshot(collection(dbAdmin, "masajid"), (snapshot) => {
            const currentTimestamp = Date.now();

            // Extract and filter future kuliah only
            const v2Data = snapshot.docs.flatMap((doc) =>
                (doc.data().aktiviti?.kuliah || [])
                    .filter(kuliah => kuliah.start?.seconds * 1000 > currentTimestamp) // Only future kuliah
                    .map(kuliah => ({
                        ...kuliah,
                        date: new Date(kuliah.start.seconds * 1000), // Convert Firestore Timestamp
                        masjidId: doc.id // Attach Masjid ID from Firestore
                    }))
            );


            console.log("🔥 Filtered Future V2 Kuliah:", v2Data);
            setV2Kuliah(v2Data);
        });

        return () => unsubscribe();
    }, []);

    // Merge V1 & V2, Sort by Date (Newest First), and Show Only 12
    const mergedKuliah = [...v2Kuliah, ...kuliahData]
        .map(kuliah => ({
            ...kuliah,
            date: kuliah.date ? new Date(kuliah.date) : null, // Ensure proper Date object
        }))
        .sort((a, b) => a.date - b.date) // Sort by ascending date (earliest first)
        .slice(0, 12); // Limit to 12 results

    console.log("🛠 Final Merged & Sorted Kuliah:", mergedKuliah);


    // Fetch V2 (Firestore) ETV Data
    useEffect(() => {
        const unsubscribe = onSnapshot(collection(dbAdmin, "masajid"), (snapshot) => {
            const v2Data = snapshot.docs
                .map((doc) => {
                    const etvImages = doc.data().etv?.etvImage;
                    return etvImages ? etvImages[etvImages.length - 1] : null; // Get the last index
                }).filter(Boolean); // Remove undefined values

            console.log("🔥 Filtered V2 ETV (index 0 only):", v2Data);
            setV2Etv(v2Data);
        });

        return () => unsubscribe();
    }, []);

    // Merge V1 & V2, prioritize V2
    const mergedEtv = [...v2Etv, ...etvData]; // V2 first, then V1

    console.log("🛠 Final Merged ETV:", mergedEtv);

    return (
        <div className="container-body">
            <ImageSlider />


            {/* Kuliah Section */}
            <div className="column-body kuliah">
                <div className="title-area">KULIAH TERKINI</div>
                <div className="cards-container">
                    {mergedKuliah.map((kuliah, index) => (
                        <div className="card" key={index}>
                            <div className="card-image">
                                <img src={kuliah.photo_src || kuliah.mosque?.photo_src || v2Masjids.find(m => m.id === kuliah.masjidId)?.profail?.gambar} alt="Masjid" />
                            </div>
                            <div className="card-content">
                                <div className="card-header">
                                    <h3>{kuliah.program || kuliah.tajuk}</h3>
                                    <p className="date">
                                        {kuliah.date
                                            ? `${getHariLong(kuliah.date)}, ${getTarikh(kuliah.date)}`
                                            : formatCustomDate(kuliah.date)} {/* Format for V2 */}
                                    </p>
                                    <p className="mosque-name">{capitalizeWords(kuliah.mosque?.name || v2Masjids.find(m => m.id === kuliah.masjidId)?.profail?.nama)}</p>
                                    <p className="ustaz-name">{kuliah.event_ustazs?.[0]?.ustaz?.short_name || kuliah.speaker}</p>
                                </div>
                                <div className="card-footer">
                                    <div className="subject">
                                        <span className="subject-label">{getSubjek(kuliah.subject_id) || kuliah.subjek || 'Umum'}</span>
                                    </div>
                                    <p className="title">{kuliah.title || kuliah.tajuk}</p>
                                </div>
                            </div>
                            <div className="avatar-wrapper">
                                <div className="avatar">
                                    <img src={kuliah.event_ustazs?.[0]?.ustaz?.photo_src || kuliah.speakerImage} alt="Ustaz" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>



            {/* ETV Section */}
            <div className="column-body etv">
                <div className="title-area">DARI ETV MASJID</div>
                <div className="cards-etv-container">
                    {mergedEtv.map((etv, index) => (
                        <div className="card-etv" key={index}>
                            <div className="image-container">
                                <img className="image-etv" src={etv.photo_src || etv} alt={`Poster ${index}`} />
                            </div>
                            <div className="overlay">{capitalizeWords(etv.mosque?.name || v2Masjids.find(m => m.etv?.etvImage?.includes(etv))?.profail?.nama)}</div>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    );
};

export default Body;
