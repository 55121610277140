import React from 'react';
import '../styles/About.css';
import Etv from '../components/Etv';

function EtvMain() {
    return (
        <div id="top" className="about-top">



            <Etv id="etv" />
            <div style={{ height: "100px" }}></div>


        </div>

    );
}

export default EtvMain;
