// src/components/Footer.js

import React from 'react';
import '../styles/Footer.css';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-container">
            <div>
                <div className='footer-main'>
                    <div className='footer-etikaf'>
                        <img src="/assets/e-icon.png" className="footer-icon" alt="Etikaf logo" onClick={() => window.location.href = '/'} />
                    </div>
                    <div className="footer-logos">
                        <img src="/assets/app-store.png" className="footer-store" alt="App Store" onClick={() => window.location.href = '/'} />
                        <img src="/assets/google-play.png" className="footer-play" alt="Google Play" onClick={() => window.location.href = '/'} />
                    </div>

                </div>
                <div className='footer-socials' >
                    {/* <div>
                    <a href="https://www.facebook.com/Etikaf" target="_blank" rel="noopener noreferrer">
                        <img src="/assets/facebook.svg" className="footer-logo" alt="Facebook" />
                    </a>
                    <a href="https://wa.me/601158885060" target="_blank" rel="noopener noreferrer">
                        <img src="/assets/whatsapp.svg" className="footer-logo" alt="WhatsApp" />
                    </a> </div> */}
                    <div className="footer-text">
                        &copy; 2017-{currentYear} Etikaf Ent.
                    </div>
                </div>
            </div>

            <div className='footer-center'>
                <a href='/mengenai'>Mengenai</a>
                <a href='/hubungi'>Hubungi</a>
                <a href='/kemaskini'>Kemaskini</a>
                <a href='/etv'>ETV</a>
                <a href="https://etikaf-admin.web.app/daftar" target="_blank" rel="noopener noreferrer">Daftar</a>
                <a href='/privasi'>Privasi</a>
            </div>



        </footer>
    );
};

export default Footer;
