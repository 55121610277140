// src/components/ImageSlider.js

import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../styles/ImageSlider.css';

const ImageSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: false,
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div>
          <img src="/assets/banner01.png" alt="Banner 1" className="slider-image"/>
        </div>
        <div>
          <img src="/assets/banner02.png" alt="Banner 2" className="slider-image"/>
        </div>
        <div>
          <img src="/assets/banner03.png" alt="Banner 3" className="slider-image"/>
        </div>
      </Slider>
    </div>
  );
}

export default ImageSlider;
