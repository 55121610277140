import React from 'react';
import '../styles/About.css';
import Pengenalan from '../components/Pengenalan';
import Mula from '../components/Mula';
import Ciri from '../components/Ciri';


function Mengenai() {


    return (
        <div id="top" className="about-top">



            <Pengenalan id="pengenalan" />
            <Mula id="mula" />
            <Ciri id="ciri" />

            <div style={{ height: "100px" }}></div>


        </div>

    );
}

export default Mengenai;
